@import "@ingka/svg-icon/style.scss";
@import "@ingka/button/style.scss";
@import "@ingka/focus/style.scss";
@import "@ingka/svg-icon/style.scss";
@import "@ingka/button/style.scss";
@import "@ingka/carousel/style.scss";
@import "@ingka/tabs/style.scss";
@import "@ingka/focus/style.scss";
@import "@ingka/table/style.scss";
@import '@ingka/focus/style.scss';
@import '@ingka/switch/style.scss';
@import "@ingka/svg-icon/style.scss";
@import "@ingka/button/style.scss";
@import "@ingka/modal/style.scss";
@import "@ingka/focus/style.scss";
@import "@ingka/svg-icon/style.scss";
@import "@ingka/accordion/style.scss";


.App {
  text-align: center;
  
  
 

}
html, body {
  height: 100%
}

main{
  min-height: 70vh;
}





.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #ffffff;
  min-height: 100vh;

  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
 
}

.App-link {
  color: #61dafb;
}

