.shadow {
    
   
 
      background-color: #fdfdfd;
    display: flex;
      align-items: center;
      justify-content: center;
 
    &.bottom {
      box-shadow: 0px 15px 10px -15px rgb(34, 34, 34);    
    }
   

}