@tailwind base;
@tailwind components;
@tailwind utilities;



body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

.modal-content{
  border-radius:10px !important;
  top: 20vh !important;
}
 .modal-header{
  border: none !important;
}
.cityInput:focus{
  outline:none !important;
}
.fieldInput{
  height:3rem !important;
    border: 1px solid #A9A9A9 !important;
    width:100% !important;
}
.switchBatch{
  max-width:15vw !important;
}

.my-modal {
    width: 90vw !important  ;  /* Occupy the 90% of the screen width */
    max-width: none !important;
    
} 
.my-modal-changes{
   max-width: none !important;
   width: 70vw !important  ;
}
.svg-icon--grey{
  fill:white !important;
}


.my-modal-decisionPoints{
   width: 95vw !important  ;  /* Occupy the 90% of the screen width */
    max-width: none !important;
}

.modal-height{
  min-height: 95vh;
}

.existingSiteDetails{
    width: 95vw !important  ;  /* Occupy the 90% of the screen width */
    max-width: none !important;
    border-radius:10px;
   
}



code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

.projectDetailsPill{
  color:white !important;
}

.search__action{
  display:none !important;
}

.editedSuccessfully{
animation: fadeInSuccess 1s;
   animation-direction: alternate;
  -webkit-animation: fadeInSuccess 1s;
  -moz-animation: fadeInSuccess 1s;
  -o-animation: fadeInSuccess 1s;
  -ms-animation: fadeInSuccess 1s;
  position: relative;
}

@keyframes fadeOut {
  0% {opacity:100%}
 
  100% {opacity:0%}
}

@keyframes fadeIn {
  0% {opacity:0; left:200px;}
 
  100% {opacity:1; left:0}
}

@keyframes fadeInSuccess {
  0% {opacity:0; bottom:0px;}
 
  100% {opacity:1; bottom:50px}
}


@keyframes fadeInList {
  0% {opacity:1; right:80px;}
 
  100% {opacity:1; right:0}
}


@keyframes fadeInDetails {
  0% {opacity:1; left:100px;}
 
  100% {opacity:1; left:0}
}

.primaryBtn{
  color:white !important;
}


.columnDropDownBtn{
  background-color: #0058a3 !important;
  border:none !important;
  padding:1vw !important;
  padding-right:2vw !important;
}

.columnMenu{
  height:30vh !important;
  overflow-y: scroll;
}
.columnDropDownBtn:focus, .primaryBtn:focus{

  box-shadow:none !important;
}
.removeFilter:hover{
color:grey;
}

.deleteUnit:hover, .editUnit:hover{
  background-color: grey;
  color:white;
  width:100%;
}

.deleteUnit, .editUnit{
  min-width: 8vw;
 padding:1vh 1vw 1vh 1vw;
 cursor: pointer;
}


.siteDetails{
  animation: fadeInDetails 1s;
   animation-direction: alternate;
  -webkit-animation: fadeInDetails 1s;
  -moz-animation: fadeInDetails 1s;
  -o-animation: fadeInDetails 1s;
  -ms-animation: fadeInDetails 1s;
  position: relative;
}

.sitesList{
animation: fadeInList 1s;
   animation-direction: alternate;
  -webkit-animation: fadeInList 1s;
  -moz-animation: fadeInList 1s;
  -o-animation: fadeInList 1s;
  -ms-animation: fadeInList 1s;
  position: relative;
}

.signIn {
   animation: fadeIn 1s;
   animation-direction: alternate;
  -webkit-animation: fadeIn 1s;
  -moz-animation: fadeIn 1s;
  -o-animation: fadeIn 1s;
  -ms-animation: fadeIn 1s;
  position: relative;
}

.emphasised{
  color:white !important;
}

